<template>
  <NavbarItem/>
  <div>
    <div id="homeComponent">
      <HomeComponent />
    </div>
    <div id="photoComponent" style="margin-top: 3vh;">
      <PhotoComponent />
    </div>
    <div id="maggInfoComponent">
      <MaggInfoComponent />
    </div>
    <div id="torneiComponent">
      <TorneiComponent />
    </div>
    <!-- <div id="risultatiComponent">
      <RisultatiComponent />
    </div>
    <div id="tabelloniComponent">
      <TabelloniComponent />
    </div>
    <div id="pagamentiComponent">
      <PagamentiComponent />
    </div> -->
    <div>
      <FooterComponent />
    </div>
  </div> 
</template>

<script>
import HomeComponent from '@/components/HomeComponent.vue'
import TorneiComponent from '@/components/TorneiComponent.vue';
/* import RisultatiComponent from '@/components/RisultatiComponent.vue';
import TabelloniComponent from '@/components/TabelloniComponent.vue'; */
/* import PagamentiComponent from '@/components/PagamentiComponent.vue'; */
import MaggInfoComponent from '@/components/MaggInfoComponent.vue'; 
import NavbarItem from '@/components/NavbarItem.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import PhotoComponent from '@/components/PhotoComponent.vue';
export default {
  name: 'HomeView',
  components: {
    HomeComponent,
    MaggInfoComponent,
    TorneiComponent,
    /* RisultatiComponent,
    TabelloniComponent, */
    /* PagamentiComponent, */
    NavbarItem,
    FooterComponent,
    PhotoComponent,
  },
}
</script>
