import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegistrationView from '../views/RegistrationView'
import AdminPageView from '../views/AdminPageView'
import LoginView from '../views/LoginView'
import TerminiCondizioniView from '../views/TerminiCondizioniView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/AdminPage',
    name: 'AdminPage',
    component: AdminPageView,
    meta: {
      needsAuth: true
    }
  },
  {
    path: '/LoginAdmin',
    name: 'LoginAdmin',
    component: LoginView
  },
  {
    path: '/Termini&Condizioni',
    name: 'TerminiCondizioniView',
    component: TerminiCondizioniView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.needsAuth) {
    next("/LoginAdmin");
  } else {
    next();
  }
});

export default router