<template>
  <div class="site-wrap">
    <div class=" " style="margin-top: 10vh;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 ml-auto">
            <div class="col-12 title-section">
              <h2 class="heading" style="color: #F26700;"><strong>Location</strong></h2>
            </div>
            <p style="margin-bottom: 5%;">
              La piscina Montecarlo di Casale Monferrato sarà il cuore dell'evento Monferrato Summer Cup. La piscina,
              situata in Strada alla Diga 1, è una delle piscine più
              belle e storiche della città. Dispone di un campo da Calcio, due campi da Beach Volley e due campi da Basket
              3vs3, nonché di due piscine all'aperto e un'ampia
              area verde. Gli spogliatoi sono dotati di docce, servizi e tutto il necessario per prepararsi prima della
              partita. Il bar della piscina sarà a disposizione a
              preparare cibo e bevande per tutti i partecipanti.
            </p>
          </div>
          <div class="col-lg-12 ml-auto">
            <div class="col-12 title-section">
              <h2 class="heading" style="color: #F26700;"><strong>Iscrizione</strong></h2>
            </div>
            <p style="margin-bottom: 5%;">
              Le iscrizioni sono aperte a tutti gli appassionati di Calcio, Basket e Beach Volley. Il costo
              dell'iscrizione è di 25€ e comprende l'iscrizione al torneo,
              l'assicurazione, l'entrata alla piscina Montecarlo per entrambi i giorni dell'evento, una maglietta firmata
              Under Armour e gadget vari offerti e siglati
              Monferrato Summer Cup. Il numero massimo e minimo di giocatori varia in base al torneo. Per il Calcio a 5 il
              numero massimo di giocatori è di 7, il minimo
              è di 5. Per il Basket 3vs3 il numero massimo è di 4, il minimo è di 3. Per il Beach Volley il numero massimo
              è di 3, il minimo è di 2. Per iscriversi basta
              compilare il modulo presente sul sito web o scrivere sulla pagina Instagram dell'evento (@monferratocup).
              Solo il capitano dovrà compilare il modulo a nome
              dell'intera squadra.
            </p>
          </div>
          <div class="col-lg-12 ml-auto">
            <div class="col-12 title-section">
              <h2 class="heading" style="color: #F26700;"><strong>Premi</strong></h2>
            </div>
            <p style="margin-bottom: 0.5%;">
              Non solo ci saranno premi in denaro per le prime tre squadre classificate di ogni torneo, ma ci saranno
              anche premi individuali come il miglior marcatore
              e il miglior giocatore di ogni torneo. Inoltre, ci saranno premi - di attività secondarie come il beer pong,
              la gara di tiro e altre challenge - offerti
              dai nostri partner. Ma soprattutto, il premio più grande sarà la possibilità di passare due giorni interi
              all'aria aperta, giocando a sport, divertendosi e socializzando con altri giovani della città.
            </p>
          </div>
        </div>
      </div>
  </div>
</div></template>

<script>
export default {
  name: 'MaggInfoComponent',
}
</script>

<style>
p {
  color: black;
}
</style>