<template>
    <!-- Start Banner Area -->
    <NavbarItem />
    <section class="banner-area organic-breadcrumb">
        <div class="container">
            <div class="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
                <div class="col-first">
                    <h1>Termini e condizioni,</h1>
                    <h1>Regolamento</h1>
                </div>
            </div>
        </div>
    </section>
    <!-- End Banner Area -->

    <!--================Login Box Area =================-->
    <section class="" style="margin-top: 13vh; margin-bottom: 6vh;">
        <div class="container">
            <div class="row fullscreen align-items-center justify-content-start">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <h2 class="heading" style="color: #FFB900; text-align: center;margin-bottom: 4vh;">
                        <strong>Termini e condizioni</strong>
                    </h2>
                    <h2 class="heading" style="color: #F26700;"><strong>Accettazione dei termini e condizioni</strong></h2>
                    <p>
                        L'iscrizione al torneo implica l'accettazione di tutti i termini e le condizioni
                        indicate in questo
                        documento. L'organizzazione del torneo si riserva il diritto di modificare i termini
                        e le condizioni in
                        qualsiasi momento e senza preavviso.
                    </p>
                    <h2 class="heading" style="color: #F26700;"><strong>Responsabilità</strong></h2>
                    <p>
                        Gli atleti partecipano al torneo a proprio rischio e pericolo. L'organizzazione del
                        torneo non sarà
                        responsabile per eventuali infortuni o danni a persone o cose derivanti dalla
                        partecipazione al torneo.
                    </p>
                    <h2 class="heading" style="color: #F26700;"><strong>Comportamento dei partecipanti</strong></h2>
                    <p>
                        Gli atleti e le squadre partecipanti sono tenuti a mantenere un comportamento
                        rispettoso e sportivo durante
                        l'intero svolgimento del torneo. L'organizzazione del torneo si riserva il diritto
                        di sospendere l'attività
                        di giocatori o squadre che non rispettano tali comportamenti, senza obbligo di
                        motivazione o di
                        risarcimento.
                    </p>
                    <h2 class="heading" style="color: #F26700;"><strong>Cambiamenti al programma</strong></h2>
                    <p>
                        L'organizzazione del torneo si riserva il diritto di apportare eventuali modifiche
                        al programma del torneo,
                        compresi i tempi di inizio e fine, senza preavviso e senza obbligo di risarcimento.
                    </p>
                    <h2 class="heading" style="color: #F26700;"><strong>Diritti sulle immagini</strong></h2>
                    <p>
                        Gli atleti e le squadre partecipanti acconsentono all'utilizzo delle loro immagini e
                        delle loro prestazioni
                        sportive per scopi pubblicitari o promozionali, senza obbligo di compensazione.
                    </p>
                    <h2 class="heading" style="color: #F26700;"><strong>Esclusione della responsabilità</strong></h2>
                    <p>
                        L'organizzazione del torneo declina qualsiasi responsabilità per eventuali danni,
                        lesioni, perdite o furti
                        di beni personali subiti dagli atleti o dai partecipanti durante il torneo.
                    </p>
                    <h2 class="heading" style="color: #FFB900; text-align: center; margin-top: 6vh; margin-bottom: 4vh;">
                        <strong>Regolamento</strong>
                    </h2>
                    <h2 class="heading" style="color: #F26700;"><strong>Partecipazione</strong></h2>
                    <p>
                        Il torneo è aperto a tutti gli atleti amatoriali, che hanno almeno 16 anni di età al momento
                        dell'evento. Gli atleti dovranno registrarsi online sul sito web del torneo e pagare la tassa di
                        iscrizione di 25€. Le iscrizioni saranno aperte fino al giorno precedente l'inizio del torneo, salvo
                        eventuali cambiamenti da parte dell'organizzazione.
                    </p>

                    <h2 class="heading" style="color: #F26700;"><strong>Sport coinvolti</strong></h2>
                    <p>
                        Il torneo consiste in tre diverse discipline: basket 3x3, calcio a 5 e beach volley. Gli atleti
                        possono
                        partecipare a una o più discipline, ma devono pagare una tassa di iscrizione separata per ciascuna
                        di
                        esse.
                    </p>

                    <h2 class="heading" style="color: #F26700;"><strong>Data e luogo</strong></h2>
                    <p>
                        Il torneo si svolgerà il 2-3 giugno 2023 presso la piscina Montecarlo, situata in Strada alla Diga 1
                        a
                        Casale Monferrato.
                    </p>

                    <h2 class="heading" style="color: #F26700;"><strong>Sistema di gioco</strong></h2>
                    <p>
                        Le squadre saranno composte da un minimo di 3 e un massimo di 4 giocatori per il basket, un minimo
                        di 5
                        e un massimo di 7 giocatori per il calcio, e da un minimo di 2 e un massimo di 3 giocatori per il
                        beach
                        volley. Le partite saranno giocate in due fasi diverse; la prima fase a gironi e poi in base alle
                        qualificazioni, ad "eliminazione diretta". Nel caso in cui una squadra non si presenti alla partita,
                        la
                        squadra avversaria vincerà per forfait.
                    </p>

                    <h2 class="heading" style="color: #F26700;"><strong>Premi</strong></h2>
                    <p>
                        Il premio in denaro sarà assegnato alla squadra vincente di ogni disciplina. L'ammontare del premio
                        sarà
                        di 800€ per il calcio a 5, 300€ per il basket 3x3 e 200€ per il beach volley. Inoltre, tutte le
                        squadre
                        che raggiungeranno la fase semifinale di ogni disciplina riceveranno una medaglia.
                    </p>

                    <h2 class="heading" style="color: #F26700;"><strong>Regole generali</strong></h2>
                    <p>
                        Tutti i partecipanti sono tenuti a rispettare le regole generali del torneo, a cui si aggiungono le
                        specifiche regole per ciascuna disciplina. Qualsiasi comportamento antisportivo, violento o
                        offensivo
                        verso gli avversari, gli arbitri o il pubblico sarà sanzionato con l'espulsione immediata dal
                        torneo.
                    </p>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </section>
    <FooterComponent />
</template>

<script>
import NavbarItem from '@/components/NavbarItem.vue';
import FooterComponent from '@/components/FooterComponent.vue';
export default {
    name: 'TerminiCondizioniView',
    components: {
        NavbarItem,
        FooterComponent,
    },
}
</script>
