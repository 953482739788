import axios from 'axios'
import API_CONF from '../../conf-api.json'
export default {
    getTeams() {
        return axios.get(API_CONF.url+'teams').then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
            return err; 
        });
    },
    addTeams(teamName, idMatchType) {
        return axios.post(API_CONF.url+'teams/add', {teamName: teamName, idMatchType: idMatchType}).then((response) => {
            return response.data;
        }).catch(() => {
            return "Error";
        });
    },
    getTeamsById(teamName) {
        return axios.get(API_CONF.url+'teams/'+ teamName).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
            return 'Error'; 
        });
    },
    deleteTeamsByName(teamName){
        return axios.delete(API_CONF.url+'teams/'+ teamName).then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
            return "Error";
        });
    }
}