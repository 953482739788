import axios from 'axios'
import API_CONF from '../../conf-api.json'
export default {
    getResults() {
        return axios.get(API_CONF.url+'results').then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
            return err; 
        });
    },
    addResults(idTeam, wonMatch, drawMatch, lostMatch, teamPoints) {
        return axios.post(API_CONF.url+'results/add', {idTeam : idTeam, wonMatch: wonMatch, drawMatch: drawMatch, lostMatch: lostMatch, teamPoints: teamPoints}).then((response) => {
            return response.data;
        }).catch(() => {
            return "Error";
        });
    },
    updateResults(idTeam, wonMatch, drawMatch, lostMatch, teamPoints) {
        return axios.put(API_CONF.url+'results/up/'+ idTeam, {wonMatch: wonMatch, drawMatch: drawMatch, lostMatch: lostMatch, teamPoints: teamPoints}).then((response) => {
            return response.data;
        }).catch(() => {
            return "Error";
        });
    }
}