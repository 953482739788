<template>
	<div class="">
		<div class="">
			<slot />
			<h1 style="margin-bottom: 3vh">Termini e Condizioni</h1>
			<h3>Accettazione dei termini e condizioni</h3>
			<p>
				L'iscrizione al torneo implica l'accettazione di tutti i termini e le condizioni indicate in questo
				documento. L'organizzazione del torneo si riserva il diritto di modificare i termini e le condizioni in
				qualsiasi momento e senza preavviso.
			</p>
			<h3>Responsabilità</h3>
			<p>
				Gli atleti partecipano al torneo a proprio rischio e pericolo. L'organizzazione del torneo non sarà
				responsabile per eventuali infortuni o danni a persone o cose derivanti dalla partecipazione al torneo.
			</p>
			<h3>Comportamento dei partecipanti</h3>
			<p>
				Gli atleti e le squadre partecipanti sono tenuti a mantenere un comportamento rispettoso e sportivo durante
				l'intero svolgimento del torneo. L'organizzazione del torneo si riserva il diritto di sospendere l'attività
				di giocatori o squadre che non rispettano tali comportamenti, senza obbligo di motivazione o di
				risarcimento.
			</p>
			<h3>Cambiamenti al programma</h3>
			<p>
				L'organizzazione del torneo si riserva il diritto di apportare eventuali modifiche al programma del torneo,
				compresi i tempi di inizio e fine, senza preavviso e senza obbligo di risarcimento.
			</p>
			<h3>Diritti sulle immagini</h3>
			<p>
				Gli atleti e le squadre partecipanti acconsentono all'utilizzo delle loro immagini e delle loro prestazioni
				sportive per scopi pubblicitari o promozionali, senza obbligo di compensazione.
			</p>
			<h3>Esclusione della responsabilità</h3>
			<p>
				L'organizzazione del torneo declina qualsiasi responsabilità per eventuali danni, lesioni, perdite o furti
				di beni personali subiti dagli atleti o dai partecipanti durante il torneo.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['TogglePopup']
}
</script>

<style scoped>

</style>