<template>
  <header class="header_area sticky-header">
    <div class="main_menu">
      <nav class="navbar navbar-expand-lg navbar-light main_box" style="border-radius: 0 0 2vh 2vh;">
        <div class="container-fluid justify-content-center">
          <div class="d-flex">
            <div class="d-flex align-items-center mr-3">
              <img src="../assets/MONFERRATO_CUP_def.png" width="130" height="105" alt="Immagine 1">
            </div>
            <div class="d-flex align-items-center">
              <img src="../assets/0.1_Krumiri.png" width="190" height="130" alt="Immagine 2" style="margin-left: 6%;">
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'NavbarItem',
}

</script>
