<template>
    <!-- Start Banner Area -->
    <NavbarItem />
    <section class="banner-area organic-breadcrumb">
        <div class="container">
            <div class="breadcrumb-banner d-flex flex-wrap align-items-center justify-content-end">
                <div class="col-first">
                    <h1>Registrazione</h1>
                </div>
            </div>
        </div>
    </section>
    <!-- End Banner Area -->

    <!--================Login Box Area =================-->
    <section class="login_box_area section_gap">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">

                </div>
                <div class="col-lg-6">
                    <div class="login_form_inner">
                        <h3>Benvenuto!</h3>
                        <form class="row login_form">
                            <div class="col-md-12 form-group">
                                <input type="text" class="form-control" placeholder="Nome" v-model="userName" required>
                            </div>
                            <div class="col-md-12 form-group">
                                <input type="text" class="form-control" placeholder="Cognome" v-model="userSurname"
                                    required>
                            </div>
                            <div class="col-md-12 form-group">
                                <input type="email" class="form-control" placeholder="Email" v-model="userEmail" required>
                            </div>
                            <div class="col-md-12 form-group">
                                <input type="text" class="form-control" placeholder="Nome Squadra" v-model="teamName"
                                    required>
                            </div>
                            <div class="col-md-12 form-group">
                                <select class="form-control" id="dropdown" required v-model="filterType" style="border-color: black; margin-top: 2%;">
                                    <option value="" selected>Seleziona il tipo</option>
                                    <option value="1">Calcetto</option>
                                    <option value="2">Basket</option>
                                    <option value="3">Beach Volley</option>
                                </select>
                            </div>

                            <div v-if="filterType">
                                <h3 style="margin-top: 3vh;">Inserisci i nomi dei giocatori</h3>
                                <div v-for="(name, index) in getPlayerNames(filterType)" :key="index">
                                    <div class="row mt-3 mb-3" style="align-items: center;">
                                        <div class="col-6 form-group">
                                            <input class="form-control" v-model="playerNames[index]"
                                                v-bind:placeholder="getRequired(filterType, index) ? 'Nome *' : 'Nome'"
                                                v-bind:required="getRequired(filterType, index)" />
                                        </div>
                                        <div class="col-6 form-group">
                                            <input class="form-control" v-model="playerSurname[index]"
                                                v-bind:placeholder="getRequired(filterType, index) ? 'Cognome *' : 'Cognome'"
                                                v-bind:required="getRequired(filterType, index)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
								<div class="creat_account">
									<input type="checkbox" id="f-option2" name="selector" @click="unseenPopUp" required>
									<label for="f-option2">Consenti i <a href="#" @click.prevent="seePopUp" style="padding: unset; margin: unset;">termini e condizioni</a></label>
								</div>
							</div>
                            <div class="col-md-12" v-if="popUp">
                                <PopupComponent />
                            </div>
                            <!-- <div class="col-md-12 form-group">
                                <PaypalItem />
                            </div> -->
                            <div class="col-md-12 form-group" style="text-align: center;">
                                <button type="submit" v-on:click="registration" class="btn btn-primary py-2 px-5"
                                    style="font-size: 1.8vh;">Iscriviti</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-3"></div>
            </div>
        </div>
    </section>
    <FooterComponent />
</template>

<script>
import PopupComponent from '@/components/PopupComponent.vue'
// import teamsService from '@/services/teams.service'
// import matchTypeService from '@/services/matchType.service'
// import MatchType from '@/model/matchType';
// import Teams from '@/model/teams';
import userService from '@/services/user.service'
import NavbarItem from '@/components/NavbarItem.vue';
import teamsService from '@/services/teams.service';
import FooterComponent from '@/components/FooterComponent.vue';
import teamsPlayersService from '@/services/teamsPlayers.service';
/* import PaypalItem from '@/components/PaypalItem.vue'; */
export default {
    name: 'RegistrationView',
    components: {
        NavbarItem,
        FooterComponent,
        PopupComponent,
        /* PaypalItem */
    },
    data() {
        return {
            userName: '',
            userSurname: '',
            userEmail: '',
            teamName: '',
            filterType: '',
            playerNames: ["", "", "", "", "", "", ""],
            playerSurname: ["", "", "", "", "", "", ""],
            recordsMatchType: [],
            idMatchType: 0,
            recordsTeams: [],
            idTeam: 0,
            popUp: false,
        }
    },
    methods: {
        getPlayerNames(option) {
            if (option == 1) {
                return this.playerNames.slice(0, 7);
            } else if (option == 2) {
                return this.playerNames.slice(0, 4);
            } else {
                return this.playerNames.slice(0, 3);
            }
        },
        getRequired(option, index) {
            if (option == 1) {
                return index < 5;
            } else if (option == 2) {
                return index < 3;
            } else {
                return index < 2;
            }
        },
        async registration() {
            if (this.userEmail.includes("@")) {
                await teamsService.addTeams(this.teamName, this.filterType).then((i) => {
                    this.idTeam = i.result.insertId;
                });

                // Adding to user table
                userService.addUsers(this.userName, this.userSurname, this.userEmail, this.idTeam).then((res) => {
                    console.log("user added" + res);
                });

                let i = 0;
                for (let e of this.playerNames) {
                    if (e !== "") {
                        teamsPlayersService.addTeamsPlayers(this.idTeam, this.playerNames[i], this.playerSurname[i],)
                    }
                    i++;
                }
                this.$router.push('/');
            }
        },
        seePopUp(){
            this.popUp = true;
        },
        unseenPopUp(){
            this.popUp = false;
        }

    },
}
</script>
