<template>
    <footer class="footer-area section_gap">
        <div class="container-fluid">
            <div class="d-flex justify-content-center">
                <div class="row">
                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/0.1_Chimigas.png" width="105" height="30" alt="Immagine 1">
                    </div>
                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/0.2_Unipolsai.png" width="170" height="70" alt="Immagine 2">
                    </div>
                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/0.3_BancoDesio.png" width="180" height="60" alt="Immagine 2">
                    </div>
                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/0.4_Byem.png" width="170" height="70" alt="Immagine 2">
                    </div>
                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/0.6_HypeLoading.png" width="90" height="90" alt="Immagine 2">
                    </div>
                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/0.5_Magnoberta.png" width="100" height="60" alt="Immagine 2">
                    </div>

                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/0.7_eventi.png" width="110" height="105" alt="Immagine 2">
                    </div>
                    <div class="col-sm-6 col-md-3 d-flex justify-content-center align-items-center mar-b">
                        <img src="../assets/sponsor/ari-logo-a.png" width="100" height="100" alt="Immagine 2">
                    </div>
                </div>
            </div>
        </div>
        <div class="container" style="margin-top: 3vh;">
            <div class="row">
                <div class="col-lg-6  col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h6 style="color: black; margin-bottom: 2vh;">Monferrato Summer Cup</h6>
                        <p>
                            Torneo multidisciplinare ⚽️🏀🏐, musica🎉 , tanta birra e salamelle🍺, minigames🃏e molte
                            sorprese!✨ 
                            Chiamate gli amici e sfidate tutti!😎
                            <br>
                            🗓️2-3 giugno 2023
                        </p>
                    </div>
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h6 style="color: black; margin-bottom: 2vh;">Social</h6>
                        <div class="footer-social d-flex align-items-center" style="margin-bottom: 2vh;">
                            <a href="https://www.instagram.com/monferratocup/"><i class="bi bi-instagram"
                                    style="color: black;"></i></a>
                            <a href="https://www.facebook.com/profile.php?id=100091520221459"><i class="bi bi-facebook"
                                    style="color: black;"></i></a>
                            <a href="https://twitter.com/MonferratoCup"><i class="bi bi-twitter"
                                    style="color: black;"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<style>
.mar-l {
    margin-left: 5%;
}

.mar-b {
    margin-bottom: 3vh;
}
</style>

<script>
export default {
    name: 'FooterComponent',
}
</script>
