<template>
    <div class="site-section bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row align-items-center">
                        <div class="col-12  mx-auto text-center">
                            <h1 class="text-white font-weight-bold">Login</h1>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <form>
            <div class="row mt-5" style="align-items: center;">
                <div class="col-4"></div>
                <div class="col-4">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Username" v-model="username" required>
                    </div>
                </div>
                <div class="col-4"></div>

            </div>
            <div class="row mt-2" style="align-items: center;">
                <div class="col-4"></div>
                <div class="col-4">
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Password" v-model="password" required>
                    </div>
                </div>
                <div class="col-4"></div>
            </div>
        
            <div class="form-group" style="text-align: center;">
                <button type="submit" v-on:click="loginAdmin" class="btn btn-primary py-2 px-5"
                    style="font-size: 1.8vh;">Login</button>
            </div>
        </form>
    </div>
</template>
  
<script>

export default {
    name: 'LoginView',
    data() {
        return{
            username: '',
            password: '',
        }
    },
    methods: {
        loginAdmin() {
            if(this.username == "pippo"){
                if(this.password == "prova"){
                    this.$router.push('/AdminPage');
                }
            }
        }
    }
}
</script>