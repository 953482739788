<template>
  <section class="banner-area" style="margin-top: 13vh;">
    <div class="container">
      <div class="row fullscreen align-items-center justify-content-start">
        <div class="col-lg-12">
          <div class="active-banner-slider">
            <!-- single-slide -->
            <div class="row single-slide align-items-center d-flex">
              <div class="col-lg-5 col-md-6">
                <div class="banner-content">
                  <div class="syn">
                    <svg viewBox="0 0 550 300">
                      <symbol id="s-text">
                        <text text-anchor="middle" x="50%" y="50%" style="text-shadow: 0 0 2px #000000;">Monferrato</text>
                        <text text-anchor="middle" x="50%" y="50%" dy="1.2em" style="text-shadow: 0 0 2px #000000;">Summer
                          Cup</text>
                      </symbol>
                      <use class="text" xlink:href="#s-text"></use>
                      <use class="text" xlink:href="#s-text"></use>
                      <use class="text" xlink:href="#s-text"></use>
                      <use class="text" xlink:href="#s-text"></use>
                      <use class="text" xlink:href="#s-text"></use>
                    </svg>
                  </div>
                  <p>La Monferrato Summer Cup è l'evento sportivo dell'estate che non puoi perdere! Tre tornei in uno,
                    Calcio a
                    5, Basket 3vs3 e Beach Volley, dove le migliori
                    squadre della città si sfideranno in una competizione senza esclusione di colpi. L'evento è stato
                    ideato per
                    riaprire le porte alla socialità e al divertimento
                    dopo il difficile periodo post-covid e per offrire un'occasione unica per i giovani di divertirsi e
                    socializzare. Non c'è alcun requisito minimo per partecipare,
                    se non quello di venire a divertirsi e di fare il tifo per la propria squadra. Il regolamento
                    dettagliato
                    sarà presente nel modulo di iscrizione che il capitano dovrà
                    accettare a nome dell'intera squadra.</p>
                  <!-- <div id="date-countdown" style="text-align: center; margin-top: 3%;">
                    <TimerItem />
                  </div> -->
                </div>
              </div>
              <div class="col-lg-7">
                <div class="banner-img">
                  <img class="img-fluid" src="../assets/HOMEPAGE_1200x720-01.jpg" alt="" style="border-radius: 1vh 1vh">
                </div> <!-- ../assets/HOMEPAGE_1200x720-01.jpg -->
              </div>
              <div class="col-lg-5">
                <!-- <p style="text-align: center;">
                  <button type="submit" v-on:click="goToRegistration" class="btn btn-primary py-2 px-4 mr-3"
                    style="margin-top: 2vh; margin-bottom: 3vh;">Registrati</button>
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* import TimerItem from '@/components/TimerItem.vue'; */
export default {
  name: 'HomeView',
  components: {
    /* TimerItem */
  },
  methods: {
    goToRegistration() {
      window.location.href = 'https://forms.gle/zkqEAfVuR9siZKcf8'
    },
  }
}
</script>

<style>
/* Main styles */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:800);

.text {
  fill: none;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-dasharray: 70 330;
  stroke-dashoffset: 0;
  -webkit-animation: stroke 6s infinite linear;
  animation: stroke 6s infinite linear;
}

.text:nth-child(5n + 1) {
  stroke: #7A0626;
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.text:nth-child(5n + 2) {
  stroke: #7A0626;
  -webkit-animation-delay: -2.4s;
  animation-delay: -2.4s;
}

.text:nth-child(5n + 3) {
  stroke: #7A0626;
  -webkit-animation-delay: -3.6s;
  animation-delay: -3.6s;
}

.text:nth-child(5n + 4) {
  stroke: #7A0626;
  -webkit-animation-delay: -4.8s;
  animation-delay: -4.8s;
}

.text:nth-child(5n + 5) {
  stroke: #7A0626;
  -webkit-animation-delay: -6s;
  animation-delay: -6s;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -400;
  }
}

/* Other styles */
.syn {
  /* background: #111;
  background-size: 0.2em 100%; */
  font: 5em/1 Open Sans, Impact;
  text-transform: uppercase;
  margin: 0;
}

svg {
  width: 100%;
  height: 100%;
}

.btn-h:hover {
  color: #fff;
  background-color: #d61036;
  border-color: #ca0f33;
}

@media (max-width: 800px) {
  .img-fluid {
    display: none;
  }
}
</style>
