<template>
    <section class="features-area section_gap" style="margin-bottom: 5vh;">
		<div class="container">
			<div class="row features-inner" style="border-radius: 10px;">
				<!-- single features -->
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="single-features">
						<div class="f-icon">
							<img src="../assets/football.png" style="max-width: 12%;">
						</div>
						<h6>Calcio</h6>
						<p>5 vs 5, massimo 7, minimo 5 persone</p>
					</div>
				</div>
				<!-- single features -->
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="single-features">
						<div class="f-icon">
							<img src="../assets/basketball.png" style="max-width: 12%;">
						</div>
						<h6>Basket</h6>
						<p>3 vs 3, massimo 4, minimo 3 persone</p>
					</div>
				</div>
				<!-- single features -->
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="single-features">
						<div class="f-icon">
							<img src="../assets/beach-volleyball.png" style="max-width: 12%;">
						</div>
						<h6>Beach Volley</h6>
						<p>2 vs 2, massimo 3, minimo 2 persone</p>
					</div>
				</div>
				<!-- single features -->
				<div class="col-lg-3 col-md-6 col-sm-6">
					<div class="single-features">
						<div class="f-icon">
							<img src="../assets/puzzle.png" style="max-width: 12%;">
						</div>
						<h6>Mini-tornei</h6>
						<p>Ping-Pong, ecc..</p>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
  name: 'TorneiComponent',
}
</script>
