<template>
    <div class="herop ">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 mx-auto text-center">
                    <div class="section-title">
                        <h1 style="color: black; font-weight: bold; color: #F26700; margin-top: 5%;">Inserisci Risultati</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <form>
            <div class="row mt-1" style="align-items: center;">
                <div class="col-4"></div>
                <div class="col-4">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Nome Team" v-model="teamName" required>
                    </div>
                </div>
                <div class="col-4"></div>

            </div>
            <div class="row mt-3" style="align-items: center;">
                <div class="col-4">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Partite Vinte" v-model="wonMatchDay" required>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Partite Pareggiate" v-model="drawMatchDay"
                            required>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Partite Perse" v-model="lostMatchDay" required>
                    </div>
                </div>
            </div>

            <div class="form-group mt-4" style="text-align: center;">
                <button type="submit" v-on:click="addResults" class="btn btn-primary py-2 px-5"
                    style="font-size: 1.8vh;">Aggiungi</button>
            </div>
        </form>
    </div>
    <div class="herop ">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 mx-auto text-center">
                    <div class="section-title">
                        <h1 style="color: black; font-weight: bold; color: #F26700; margin-top: 8%;">Elimina Squadra</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <form>
            <div class="row mt-1" style="align-items: center;">
                <div class="col-4"></div>
                <div class="col-4">
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Nome Team" v-model="teamNameToRemove" required>
                    </div>
                </div>
                <div class="col-4">
                    <button type="submit" v-on:click="removeTeam" class="btn btn-primary py-2 px-5 mb-3"
                        style="font-size: 1.8vh;">Rimuovi</button>
                </div>
            </div>
        </form>
    </div>
</template>
  
<script>
import teamsService from '@/services/teams.service';
import resultService from '@/services/result.service';
export default {
    name: 'AdminPageView',
    data() {
        return {
            teamName: '',
            teamNameToRemove: '',
            wonMatchDay: '',
            drawMatchDay: '',
            lostMatchDay: '',
        }
    },
    methods: {
        loginAdmin() {
            if (this.username == "pippo") {
                if (this.password == "prova") {
                    this.$router.push('/AdminPage');
                }
            }
        },
        addResults() {
            teamsService.getTeamsById(this.teamName).then((i) => {
                let newWon = parseInt(this.wonMatchDay) + i[0].wonMatch;
                let newDraw = parseInt(this.drawMatchDay) + i[0].drawMatch;
                let newLost = parseInt(this.lostMatchDay) + i[0].lostMatch;
                let newPoints = (3 * newWon) + (2 * newDraw);
                resultService.updateResults(i[0].idTeam, newWon, newDraw, newLost, newPoints).then(
                    () => {
                        console.log("successfully added");
                    });
            });
        },
        removeTeam() {
            teamsService.deleteTeamsByName(this.teamNameToRemove).then(() => {
                this.teamNameToRemove = '';
            });
        }
    }
}
</script>