import axios from 'axios'
import API_CONF from '../../conf-api.json'
export default {
    getUsers() {
        return axios.get(API_CONF.url+'users').then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
            return err;
        });
    },
    addUsers(userName, userSurname, userEmail, idTeam) {
        return axios.post(API_CONF.url+'users/add', {userName: userName, userSurname: userSurname, userEmail: userEmail, idTeam: idTeam}).then((response) => {
            return response.data;
        }).catch(() => {
            return "Error";
        });
    }
}