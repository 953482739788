import axios from 'axios'
import API_CONF from '../../conf-api.json'
export default {
    getTeamsPlayers() {
        return axios.get(API_CONF.url+'teamsPlayers').then((response) => {
            return response.data;
        }).catch((err) => {
            console.log(err);
            return err; 
        });
    },
    addTeamsPlayers(idTeam, playerName, playerSurname) {
        return axios.post(API_CONF.url+'teamsPlayers/add', {idTeam : idTeam, playerName: playerName, playerSurname: playerSurname}).then((response) => {
            return response.data;
        }).catch(() => {
            return "Error";
        });
    }
}