<template>
    <div class="containerr">
        <div class="card">
            <img src="../assets/photo/Monferrato-Cup-9.jpg">
            <div class="card__head">Calcio</div>
        </div>
        <div class="card">
            <img
                src="../assets/photo/Monferrato-Cup-1.jpg">
            <div class="card__head">Basket</div>
        </div>
        <div class="card">
            <img
                src="../assets/photo/Monferrato-Cup-87.jpg">
            <div class="card__head">Beach Volley</div>
        </div>
        <div class="card">
            <img
                src="../assets/photo/Monferrato-Cup-15.jpg">
            <div class="card__head">Altro</div>
        </div>
    </div>

    <div>
        <p style="text-align: center;">
                  <button type="submit" v-on:click="goToScarica" class="btn btn-primary py-2 px-4 mr-3"
                    style="margin-bottom: 1vh;">Scarica ora</button>
        </p>
    </div>
</template>

<style>


.containerr {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10vmin;
    overflow: hidden;
    transform: skew(5deg);
}

.containerr .card {
    flex: 1;
    transition: all 1s ease-in-out;
    height: 75vmin;
    position: relative;
}

.containerr .card .card__head {
    color: white;
    font-weight: bold;
    background: #FE8D00;
    padding: 0.5em;
    transform: rotate(-90deg);
    transform-origin: 0% 0%;
    transition: all 0.5s ease-in-out;
    min-width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1em;
    white-space: nowrap;
}

.containerr .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s ease-in-out;
    
}

.containerr .card:not(:nth-child(5)) {
    margin-right: 1em;
}</style>

<script>
export default {
    name: 'PhotoComponent',
    methods: {
        goToScarica() {
            window.location.href = 'https://drive.google.com/drive/folders/1TbfTw1RYO2hq7i25IXYanoc6nphmzCRn?usp=drive_link'
        }
    }
}
</script>